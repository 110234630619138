import axios from 'axios';
import { refresh } from './refresh';
import { checkResponse, checkError } from './checkError';

const HTTP_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

const axiosInstance = axios.create({
  baseURL:
    process.env.SERVER_ENV === 'production'
      ? 'https://api.localstitch.kr/'
      : 'https://api.localstitch-er.com/',
});

// axiosInstance.interceptors.request.use(refresh);

axiosInstance.interceptors.response.use(checkResponse, checkError);

const globalConfig = {
  validateStatus: status => {
    return status < 500;
  },
};

const call =
  (instance, methodType) =>
  (url, ...params) => {
    const cfg = params.pop();
    const data = params.pop();

    const config = {
      ...cfg,
      ...globalConfig,
    };

    return instance[methodType](url, data, config).catch(error => {
      if (error.response && error.response.status === 500) {
        // eslint-disable-next-line no-console
        console.log(error.response);
      }
    });
  };

const call2 =
  (instance, methodType) =>
  (url, ...params) => {
    const cfg = params.pop();

    const config = {
      ...cfg,
      ...globalConfig,
    };

    return instance[methodType](url, config).catch(error => {
      if (error.response && error.response.status === 500) {
        // eslint-disable-next-line no-console
        console.log(error.response);
      }
    });
  };

const callGet = call2(axiosInstance, HTTP_METHODS.GET);
const callPost = call(axiosInstance, HTTP_METHODS.POST);
const callPUT = call(axiosInstance, HTTP_METHODS.PUT);
const callDELETE = call2(axiosInstance, HTTP_METHODS.DELETE);
const callPATCH = call(axiosInstance, HTTP_METHODS.PATCH);

export const get = (url, config = {}) => callGet(url, config);
export const post = (url, payload, config = {}) =>
  callPost(url, payload, config);
export const put = (url, payload, config = {}) => callPUT(url, payload, config);
export const del = (url, config = {}) => callDELETE(url, config);
export const patch = (url, payload, config = {}) =>
  callPATCH(url, payload, config);
