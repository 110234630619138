import * as Sentry from '@sentry/nextjs';

const checkResponse = response => {
  if (response && response.status) return response;

  Sentry.setContext('response', response);
  Sentry.captureException(new Error('Response Undefined, at checkResponse'));
};

const checkError = error => {
  Sentry.setContext('error_response', error.response);
  if (error.response === undefined || error.response.status === undefined) {
    Sentry.captureException(new Error('Response or StatusCode is Undefined'));
  }
  return Promise.reject(error);
};

export { checkResponse, checkError };
